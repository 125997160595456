import { useState } from 'react';
import asLogo from '../../assets/as-logo.svg';
import { scroller } from 'react-scroll';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid';
import { Link, useNavigate, useLocation } from 'react-router-dom';

export function NavBar() {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation().pathname;

  const scrollToPortfolio = () => {
    scroller.scrollTo('portfolio', {
      spy: true,
      smooth: true,
      offset: 50,
      duration: 500,
    });
    console.log(location);
  };

  const scrollToPortfolioDiffPage = async () => {
    await navigate('/');
    await scroller.scrollTo('portfolio', {
      spy: true,
      smooth: true,
      offset: 50,
      duration: 500,
    });
  };

  return (
    <nav
      className="bg-light-background top-0 px-8 py-8 sm:px-24 sm:py-20 sm:pb-6"
      id="top"
    >
      <div className="flex flex-wrap items-center justify-between">
        <Link to="/">
          <img src={asLogo} alt="LOGO" className="hover:fill-gray-400" />
        </Link>
        <div className="mobile-menu block md:hidden">
          {!navbarOpen ? (
            <button
              onClick={() => setNavbarOpen(true)}
              className="border-black-500 text-black-500 flex items-center rounded border px-3 py-2 hover:border-gray-400 hover:text-gray-400"
            >
              <Bars3Icon className="h-5 w-5" />
            </button>
          ) : (
            <button
              onClick={() => setNavbarOpen(false)}
              className="border-black-500 text-black-500 flex items-center rounded border px-3 py-2 hover:border-gray-400 hover:text-gray-400"
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          )}
        </div>
        <div className="hidden flex-row space-x-20 md:block">
          <button
            onClick={
              location === '/' ? scrollToPortfolio : scrollToPortfolioDiffPage
            }
            className="cursor-pointer text-center text-[20px] font-normal text-black hover:text-gray-400 active:text-gray-400"
          >
            Portfolio
          </button>
          <Link to="/artwork">
            <button className="text-center text-[20px] font-normal text-black hover:text-gray-400">
              Artwork
            </button>
          </Link>
          <Link
            to="/about"
            className="text-center text-[20px] font-normal text-black hover:text-gray-400"
          >
            <button>About</button>
          </Link>
        </div>
      </div>
      {navbarOpen ? (
        <div className="flex flex-col justify-center space-y-10 pt-10">
          <button
            onClick={
              location === '/' ? scrollToPortfolio : scrollToPortfolioDiffPage
            }
            className="text-center text-[18px] font-normal text-black hover:text-gray-400"
          >
            Portfolio
          </button>
          <Link
            to="/artwork"
            className="text-center text-[18px] font-normal text-black hover:text-gray-400"
          >
            <button>Artwork</button>
          </Link>
          <Link
            to="/about"
            className="text-center text-[18px] font-normal text-black hover:text-gray-400"
          >
            <button>About</button>
          </Link>
        </div>
      ) : null}
    </nav>
  );
}
