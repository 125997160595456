export function Info() {
  return (
    <div
      className="flex flex-col items-end px-8 pt-8 sm:items-start sm:px-24 sm:py-20"
      id="home"
    >
      <h1 className="text-xl sm:text-lg">
        Digital Designer based in Copenhagen
      </h1>
      <div className="grid grid-cols-1 place-items-start gap-x-0 gap-y-1 pt-6">
        <p>UX DESIGN &emsp; INTERACTION</p>
        <p className="col-span-1">CONCEPT DEVELOPMENT</p>
        <p>UI DESIGN &emsp; GRAPHIC DESIGN</p>
        <p>FIGMA &emsp; ADOBE</p>
      </div>
    </div>
  );
}
