import { Link } from "react-scroll";
import asLogo from "../../assets/as-logo.svg";
import purpleFlower from "../../assets/purple-flower.png";

export function Footer() {
  return (
    <footer className="bg-light-background">
      <hr className="mx-24 mt-16 hidden border-black sm:block"></hr>
      <div className="hidden flex-row items-center justify-between px-24 py-20 md:flex">
        <Link to="top" spy={true} smooth={true} offset={50} duration={500}>
          <img src={asLogo} alt="footer-logo" height="100" width="100" />
        </Link>
        <img src={purpleFlower} alt="purple-flower" height="100" width="100" />
        <div className="flex flex-col items-start gap-y-2">
          <a
            href="mailto:anjalisangwan5@gmail.com"
            className="hover:text-gray-400"
          >
            anjalisangwan5@gmail.com
          </a>
          <a
            href="https://Linkedin.com/in/anjalisangwan"
            className="hover:text-gray-400"
          >
            LinkedIn
          </a>
        </div>
      </div>
      <div className="block flex flex-col items-center gap-3 pb-10 md:hidden">
        <a
          href="mailto:anjalisangwan5@gmail.com"
          className="text-xl hover:text-gray-400"
        >
          anjalisangwan5@gmail.com
        </a>
        <a
          href="https://Linkedin.com/in/anjalisangwan"
          className="text-xl hover:text-gray-400"
        >
          LinkedIn
        </a>
        <img src={purpleFlower} alt="" className="h-18 w-12 pt-10"></img>
      </div>
    </footer>
  );
}
