import { Footer } from "../components/shared-components/Footer";
import { NavBar } from "../components/shared-components/NavBar";
import smallRedFlower from "../assets/red-flower.svg";
import smallPinkFlower from "../assets/pink-flower.svg";
import anjaliPortrait from "../assets/Anjali-Portrait.png";

export function AboutPage() {
  return (
    <>
      <NavBar />
      <div className="px-8 pb-20 pt-10 sm:px-24 sm:pt-0">
        <div className="flex justify-end space-x-6 sm:pt-40">
          <img src={smallPinkFlower} alt="" />
          <h1 className="text-5xl sm:text-7xl">About Me</h1>
          <img src={smallRedFlower} alt="" />
        </div>
        <div className="pt-10 sm:flex sm:items-center sm:justify-center">
          <div className="flex flex-col-reverse sm:flex-row sm:space-x-24">
            <img
              src={anjaliPortrait}
              alt=""
              className="h-96 w-72 self-end bg-stone-300"
            ></img>
            <p className="w-3/4 pb-8 text-sm sm:w-1/2">
              I have had a deep passion for art since childhood, drawn to the
              vibrant world of colors and the creative possibilities it offered.
              Painting is my preferred medium for expressing emotions and ideas,
              and I constantly seek to push the boundaries of what I can achieve
              through the exploration of new techniques, styles, and subjects.
              My interest in art led me to explore digital design, which
              resulted in my pursuing an education in Multimedia Design and a
              bachelor's degree in Digital Concept Development. For the past
              four years, I've worked as a UX designer, with my primary emphasis
              being on design and development of digital interfaces.
            </p>
          </div>
        </div>
        <div className="pt-10 sm:flex sm:flex-row sm:space-x-24 sm:pt-20">
          <div className="sm:w-1/3">
            <h1 className="text-3xl sm:text-2xl">Work Experience</h1>
            <div className="pt-10">
              <h1 className="text-xl font-bold">Lead Designer</h1>
              <h1 className="text-lg">Nexttier, Full-time</h1>
              <p className="text-gray-400">2021-2023</p>
              <p className="pt-3 text-sm">
                I have worked as a Design Lead at NextTier, specializing in web
                and mobile application design with a focus on user experience
                and interface. I've successfully led and trained interns,
                transferring my expertise remotely, resulting in their
                transition to Associate UI/UX roles.
              </p>
            </div>
            <div className="pt-10">
              <h1 className="text-xl font-bold">UX / UI Designer</h1>
              <h1 className="text-lg">Shortcut, Part-time</h1>
              <p className="text-gray-400">2020-2021</p>
              <p className="pt-3 text-sm">
                I have worked on UX/UI projects for companies such as Clever,
                Dansk Metal, and Jabra. Projects included enhancing user
                experience for Dansk Metal by enabling seamless document
                sharing, exploring user charging experiences in the Clever App,
                conducting a UX case study on Jabra Sound Plus for Active Noise
                Cancellation
              </p>
            </div>
            <div className="pt-10">
              <h1 className="text-xl font-bold">Design Assistant</h1>
              <h1 className="text-lg">Loyalty Key, Internship</h1>
              <p className="text-gray-400">2020-2020</p>
              <p className="pt-3 text-sm">
                Created graphic content for webshops and social media.
                Contributed to strategic development and maintenance of various
                platforms. Came up with graphic ideas for campaigns that hit the
                trends of the time.
              </p>
            </div>
          </div>
          <div className="pt-10 sm:w-1/3 sm:pt-0">
            <h1 className="text-3xl sm:text-2xl">Education</h1>
            <div className="pt-10">
              <h1 className="text-xl font-bold">Digital Concept Development</h1>
              <h1 className="text-lg">Bachelor of Arts - BA</h1>
              <p className="text-gray-400">2020-2022</p>
              <p className="pt-3 text-sm">
                In this program, I acquired the skills to understand and apply
                technologies in concept development, covering the entire
                development process from data collection to prototype testing.{" "}
              </p>
            </div>
            <div className="pt-8 sm:pt-10">
              <h1 className="text-xl font-bold">
                Multimedia Design & Communication
              </h1>
              <h1 className="text-lg">AP Degree</h1>
              <p className="text-gray-400">2018-2020</p>
              <p className="pt-3 text-sm">
                As a Multimedia designers I have created visually engaging
                content by combining graphics, audio, and video for digital
                platforms, ensuring cohesive user experiences.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
