import { Banner } from '../components/Banner';
import { Contact } from '../components/Contact';
import { DesignPreview } from '../components/DesignPreview';
import { Footer } from '../components/shared-components/Footer';
import { Info } from '../components/Info';
import { NavBar } from '../components/shared-components/NavBar';

export function HomePage() {
  return (
    <div>
      <div>
        <NavBar />
        <Info />
        <Banner />
        <DesignPreview />
        <Contact />
        <Footer />
      </div>
    </div>
  );
}
