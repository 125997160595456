import { useState } from "react";
import { NavBar } from "../components/shared-components/NavBar";
import { Footer } from "../components/shared-components/Footer";

export function ArtworkPage() {
  const [buttonState, setButtonState] = useState("Digital");

  function importAll(r: __WebpackModuleApi.RequireContext) {
    let images = r
      .keys()
      .filter((key) => key.match(/\.\//))
      .map(r);
    return images as string[];
  }

  const digitalImages = importAll(
    require.context("../assets/artwork-digital/", false, /\.(png)$/)
  );

  const handpaintedImages = importAll(
    require.context("../assets/artwork-handpainted/", false, /\.(png)$/)
  );

  function shownImages() {
    let images: string[] = [""];
    images = buttonState === "Digital" ? digitalImages : handpaintedImages;
    return images;
  }

  return (
    <div>
      <NavBar />
      <div className="grid grid-cols-2 gap-8 px-8 pb-8 pt-6 sm:px-24">
        <div className="col-span-2 flex flex-row space-x-10">
          <button
            onClick={() => setButtonState("Digital")}
            className={`${
              buttonState === "Digital" ? "font-bold underline" : "text-black"
            }`}
          >
            Digital
          </button>
          <button
            onClick={() => setButtonState("Handpainted")}
            className={`${
              buttonState === "Handpainted"
                ? "font-bold underline"
                : "text-black"
            }`}
          >
            Handpainted
          </button>
        </div>
        {shownImages().map((image) => (
          <img src={image} alt=""></img>
        ))}
      </div>
      <Footer />
    </div>
  );
}
