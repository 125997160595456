import { NavBar } from "../components/shared-components/NavBar";
import AF1 from "../assets/AF-1.png";
import AF2 from "../assets/AF-2.png";
import AF3 from "../assets/AF-3.png";
import AF4 from "../assets/AF-4.png";
import AF5 from "../assets/AF-5.png";
import AF6 from "../assets/AF-6.png";
import AFGamesCollage from "../assets/af-collage.png";
import lederne1 from "../assets/leder-banner.png";
import lederne2 from "../assets/lederne-challenge.png";
import lederne3 from "../assets/lederne-3.png";
import vade1 from "../assets/vade-1.png";
import vade2 from "../assets/vade-2.png";
import vade3 from "../assets/vade-3.png";
import vade4 from "../assets/vade-4.png";
import vade5 from "../assets/vade-5.png";
import vade6 from "../assets/vade-6.png";
import cash1 from "../assets/cash-1.png";
import cash2 from "../assets/cash-2.png";
import cash3 from "../assets/cash-3.png";
import cash4 from "../assets/cash-4.png";
import { Footer } from "../components/shared-components/Footer";
import { useParams } from "react-router-dom";

export function ProjectPage() {
  const params = useParams();
  const projectId = params.id;

  type Project = {
    id: string;
    title: string;
    capabilities: string[];
    client: string;
    year: string;
    banner: string;
    background: string;
    picture1: string;
    description1: string;
    picture2: string;
    description2: string;
    picture3: string;
    description3: string;
    picture4: string;
    description4: string;
    picture5: string;
    description5: string;
    picture6: string;
    description6: string;
  };

  const projects: Project[] = [
    {
      id: "AFGames",
      title:
        "What is the most effective brand concept  centered around a fictional character Annie-Mae, a time-traveling Fashionista representing diverse fashion eras, targeting women aged 16-25 passionate about fashion and online personalities?",
      capabilities: ["Concept Development", "Branding"],
      client: "Arty Farty Games",
      year: "2022",
      banner: "",
      background: AFGamesCollage,
      picture1: AF2,
      description1:
        "Annie Mae is the face of Annie Mae Beauty, offering vegan and perfume-free products primarily aimed at Lolita and anime fans, including younger women starting from 15 years old. The makeup line, designed for Lolita Fashion enthusiasts, features beautiful colors and packaging.",
      picture2: AF3,
      description2:
        "Annie Mae, along with her three friends symbolizing different Lolita genres, represents distinct product series within the brand.",
      picture3: AF4,
      description3: "",
      picture4: AF5,
      description4: "",
      picture5: AF6,
      description5: "",
      picture6: AF1,
      description6: "",
    },
    {
      id: "Lederne",
      title:
        "How can we develop an appealing digital platform to enhance the provision and management of student memberships for Lederne, a Danish trade union?",
      capabilities: ["UI", "Prototype", "Concept Development", "Figma"],
      client: "Lederne Plus",
      year: "2021",
      banner: "",
      background: "",
      picture1: lederne1,
      description1:
        "Many Danish trade unions, including Lederne with its 111,000 members, actively reach out to students, offering membership perks such as career guidance, event participation, and access to discounted insurance and experiences. Despite primarily catering to employed leaders, Lederne aims to spark students' interest in leadership, fostering early awareness and affiliation. As part of this initiative, Lederne has decided to extend membership opportunities to students.",
      picture2: lederne2,
      description2:
        "The purpose of the app was to make it a part of the daily routine for young students and novice leaders, providing a foundation for professional and personal development at an early stage.",
      picture3: lederne3,
      description3:
        "It also aimed to give them a sense of security in the future job market, ensuring this security through the app's personal approach via the my field and a virtual personal assistant, Olivia. Now, assistance should not be difficult to find, as the app creates space for effective communication and development through the visualization of progress.",
      picture4: "",
      description4: "",
      picture5: "",
      description5: "",
      picture6: "",
      description6: "",
    },
    {
      id: "Vadehavet",
      title:
        "How can we ripple up the tourist adventure in the Wadden Sea, a national heritage? Our goal? Making their adventure an aquatic escapade. ",
      capabilities: ["UX, UX, CX", "Prototype", "Branding"],
      client: "Vadehavets Gemmer",
      year: "2022",
      banner: "",
      background: "",
      picture1: vade1,
      description1:
        "For several years, the Wadden Sea National Park has struggled to increase the number of visitors. People often see the Wadden Sea as just water and long walks. Our vision was to reveal the hidden gems of the National Park Wadden Sea, offering a diverse range of experiences. We want everyone to discover that the Wadden Sea is more than a vast blue expanse; it's a playground of wonders waiting to be explored",
      picture2: vade2,
      description2:
        "We have crafted a web app that's a family-friendly haven. This all-in-one platform unfolds the exciting realm of Wadden Sea fishing adventures, both on water and land.",
      picture3: vade3,
      description3:
        "Explore all corners with the stamp card. The concept of the competition revolves around stamp cards and stickers. The stamp card includes locations of National Park Wadden Sea partners, including museums, parks, and other related activities. A sticker is awarded each time you visit a destination. A specific number of stickers must be collected before receiving a prize. The purpose of this concept is to create a broader interaction in the Wadden Sea area.",
      picture4: vade4,
      description4: "",
      picture5: vade5,
      description5: "",
      picture6: vade6,
      description6:
        "Øivind, a cheerful fish, is passionately dedicated to sharing the myriad experiences the Wadden Sea has to offer. His mission is to bring the story to life, making it engaging for everyone. The mascot intended to provide a unique identity for the Wadden Sea National Park, enhancing recognition. Opting for a mascot as an on-site storyteller, rather than relying on factual information or human guides, had the potential to propel the park as a brand.",
    },
    {
      id: "Cashback",
      title:
        "How can the design of an existing cashback app be effectively redesigned to enhance its modernity and user experience?",
      capabilities: ["UI", "Prototype", "Graphic Content"],
      client: "Cashback App",
      year: "2019",
      banner: "",
      background: "",
      picture1: cash1,
      description1:
        "Cashback is embarking on a mission to redefine its brand in a modern way. Traditionally, the platform has offered cashbacks through its website over an extended period. However, with the evolving times, users predominantly opt for apps due to their convenience and accessibility. Our challenge was to rejuvenate the existing platform, providing it with a fresh appearance while retaining elements of its original identity.",
      picture2: cash2,
      description2:
        "We suggested a few weeks plan to transform the Cashback brand in line with its updated business strategy and target audience. The initial weeks focused on gaining relevant internal and external market insights. This laid the groundwork. In the following next weeks of strategy, we built a strong foundation for a new verbal and visual identity that reflected our vision, value proposition, and customer needs and wants.",
      picture3: "",
      description3: "Icons, typography, and colors",
      picture4: cash3,
      description4: "",
      picture5: cash4,
      description5: "",
      picture6: "",
      description6: "",
    },
  ];

  let project = projects.find((element) => element.id === projectId);

  function getBanner(projectId: string | undefined) {
    if (projectId === "AFGames") {
      return (
        <div className="bg-af-bg bg-auto bg-center">
          <NavBar />
          <img
            src={project?.background}
            alt=""
            className="m-auto px-8 pb-3 pt-20 sm:w-1/2 sm:px-0"
          />
        </div>
      );
    } else if (projectId === "Lederne") {
      return (
        <div className="h-72 bg-led-bg bg-center sm:h-96">
          <NavBar />
        </div>
      );
    } else if (projectId === "Vadehavet") {
      return (
        <div className="h-72 bg-vad-bg bg-cover sm:h-96">
          <NavBar />
        </div>
      );
    } else if (projectId === "Cashback") {
      return (
        <div className="h-72 bg-csh-bg bg-cover sm:h-96">
          <NavBar />
        </div>
      );
    } else {
      return (
        <div className="h-96 bg-[#EAE7E4] sm:h-96">
          <NavBar />
        </div>
      );
    }
  }

  return (
    <div className="bg-white">
      {getBanner(projectId)}
      <div className="flex flex-col-reverse bg-white p-8 sm:flex sm:flex-row sm:space-x-20 sm:px-24 sm:pt-24">
        <div className="flex justify-between sm:block">
          <div>
            <h1 className="font-semibold">Capabilities</h1>
            {project?.capabilities.map((capabilities) => <p>{capabilities}</p>)}
          </div>
          <div className="sm:px-0 sm:pt-6">
            <h1 className="font-semibold">Year</h1>
            <p>{project?.year}</p>
          </div>
        </div>
        <div className="pb-8 sm:w-full sm:pb-0">
          <p className="text-2xl">{project?.title}</p>
        </div>
      </div>
      <div className="bg-white px-8 sm:px-24 sm:pt-10">
        <img
          src={project?.picture1}
          alt=""
          className="sm:h-full sm:w-full"
        ></img>
      </div>
      <div className="px-8 pt-8 sm:flex sm:flex-row sm:justify-end sm:px-24 sm:pt-10">
        <p className="sm:w-3/4 sm:text-lg">{project?.description1}</p>
      </div>
      <div className="px-8 pt-8 sm:px-24 sm:pt-10">
        <img
          src={project?.picture2}
          alt=""
          className="sm:h-full sm:w-full"
        ></img>
        <p className="pt-8 sm:w-3/4 sm:pt-10 sm:text-lg">
          {project?.description2}
        </p>
      </div>
      <div className="flex flex-col px-8 pt-8 sm:px-24 sm:pt-10">
        <img src={project?.picture3} alt="" className="h-full w-full"></img>
        <p className="sm: pt-8 sm:w-3/4 sm:pt-10 sm:text-lg">
          {project?.description3}
        </p>
      </div>
      <div className="flex flex-col px-8 pt-8 sm:px-24 sm:pt-10">
        <img
          src={project?.picture4}
          alt=""
          className={` ${
            project?.id === "Cashback"
              ? "self-center sm:h-1/2 sm:w-1/2"
              : "self-center sm:h-full sm:w-full"
          }`}
        ></img>
        <p className="pt-8 sm:w-3/4 sm:self-start sm:pt-10 sm:text-lg">
          {project?.description4}
        </p>
      </div>
      <div className="px-8 pt-8 sm:px-24 sm:pt-10">
        <img
          src={project?.picture5}
          alt=""
          className="sm:h-full sm:w-full"
        ></img>
        <p className="pt-8 sm:w-3/4 sm:pt-10 sm:text-lg">
          {project?.description5}
        </p>
      </div>
      <div className="px-8 pt-8 sm:px-24 sm:pt-10">
        <img
          src={project?.picture6}
          alt=""
          className="sm:h-full sm:w-full"
        ></img>
        <p className="pt-8 sm:w-3/4 sm:pt-10 sm:text-lg">
          {project?.description6}
        </p>
      </div>
      <Footer />
    </div>
  );
}
