import { Card, TCard } from "./Card";
import { Element } from "react-scroll";
import afGamesPreview from "../assets/card-preview-afgames.png";
import ledernePreview from "../assets/card-preview-lederne.png";
import cashbackPreview from "../assets/card-preview-cashback.png";
import vadehavetPreview from "../assets/card-preview-waves.png";

export function DesignPreview() {
  const card1: TCard = {
    id: "Lederne",
    title: "Lederne Plus",
    description:
      "Increase students curiosity about leadership and their interest in leadership.",
    img: ledernePreview,
  };

  const card2: TCard = {
    id: "AFGames",
    title: "Arty Farty Games",
    description: "Branding a new phenomenon",
    img: afGamesPreview,
  };

  const card3: TCard = {
    id: "Cashback",
    title: "Cashback App",
    description:
      "Redesign of current platform, with a modern & trendy approach",
    img: cashbackPreview,
  };

  const card4: TCard = {
    id: "Vadehavet",
    title: "Vadehavets Gemmer",
    description: "An universe of fishing and nature experiences",
    img: vadehavetPreview,
  };

  return (
    <Element
      name="portfolio"
      className="flex flex-col items-center justify-start px-8 py-14 sm:px-24 sm:py-20"
    >
      <p className="self-end pb-7">Selected Works</p>
      <div className="grid gap-4 sm:grid-cols-2 ">
        <Card
          id={card4.id}
          title={card4.title}
          description={card4.description}
          img={card4.img}
        />
        <Card
          id={card3.id}
          title={card3.title}
          description={card3.description}
          img={card3.img}
        />
        <Card
          id={card2.id}
          title={card2.title}
          description={card2.description}
          img={card2.img}
        />
        <Card
          id={card1.id}
          title={card1.title}
          description={card1.description}
          img={card1.img}
        />
      </div>
    </Element>
  );
}
