import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import { ProjectPage } from "./pages/ProjectPage";
import { HomePage } from "./pages/HomePage";
import { AboutPage } from "./pages/AboutPage";
import { ArtworkPage } from "./pages/ArtworkPage";
import { useEffect } from "react";

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />}></Route>
        <Route path="/project/:id" element={<ProjectPage />}></Route>
        <Route path="/about" element={<AboutPage />}></Route>
        <Route path="/artwork" element={<ArtworkPage />}></Route>
      </Routes>
    </>
  );
}

export default App;
