import { Link } from "react-router-dom";

export type TCard = {
  id: string;
  title: string;
  description: string;
  img: string;
};

export const Card = (card: TCard) => {
  return (
    <div className="group cursor-pointer bg-transparent hover:bg-purple-800 hover:drop-shadow-2xl">
      <Link to={`/project/${card.id}`}>
        <div className="hidden bg-transparent px-5 py-6 group-hover:block">
          <div className="text-xl text-white">{card.title}</div>
          <p className="text-base text-white">{card.description}</p>
        </div>
        <img
          src={card.img}
          alt="Sunset in the mountains"
          className="object-fit h-full w-full group-hover:invisible sm:group-hover:hidden"
        />
      </Link>
    </div>
  );
};
