import bgBanner from "../assets/bg-banner.png";
import bgMobileBanner from "../assets/bg-mobile-banner.png";

export function Banner() {
  return (
    <>
      <img src={bgBanner} alt="" className="hidden sm:block"></img>
      <div className="flex justify-center pt-20 sm:hidden">
        <img src={bgMobileBanner} alt="" className="w-screen"></img>
      </div>
    </>
  );
}
